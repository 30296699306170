import * as SentryType from '@sentry/browser';
import { detectNativePlatform } from 'Environment/setup/nativeDetection';
import { BaseLogger } from './BaseLogger';
import { getErrorGroupId, parseErrorForLogging } from './ErrorHelpers';
import {
	AddBreadcrumb,
	LogError,
	LogInfo,
	SetLoggingTags,
	WSUI_SESSION_ID,
} from './LoggingProvider';

function getSentry(): typeof SentryType {
	return window.CTXSHTML_GLOBALS?.SENTRY_SDK ? window['Sentry'] : null;
}

class SentryLogger extends BaseLogger {
	private hub: SentryType.Hub;

	public constructor(options?: SentryType.BrowserOptions) {
		super();
		const sentry = getSentry();
		if (!sentry) {
			return;
		}

		try {
			this.hub = new sentry.Hub(
				new sentry.BrowserClient({
					...CTXSHTML_GLOBALS.SENTRY_SDK.options,
					...(options || {}),
				})
			);
		} catch (e) {
			sentry.captureException(e);
		}
	}

	public configureScope = (callback: (scope: SentryType.Scope) => void) => {
		this.hub?.configureScope(callback);
	};

	public logInfo: LogInfo = (message, additionalContext) => {
		this.hub?.withScope(scope => {
			scope.setExtras(additionalContext);
			this.hub.captureMessage(message, getSentry().Severity.Info);
		});
	};

	public logError: LogError = (payload, options = {}, silent = false) => {
		if (!payload) {
			payload = new Error('An undefined or empty error payload was provided');
			payload.name = 'UnsupportedErrorPayloadException';
		}
		const { error, extractedContext } = parseErrorForLogging(
			payload,
			options.customMessage
		);
		if (error.name === 'ApiError') {
			this.logInfo(error.message, extractedContext);
			return;
		}
		this.hub?.withScope(scope => {
			if (options.additionalContext?.level) {
				scope.setLevel(options.additionalContext?.level);
			}
			if (options.additionalContext || extractedContext) {
				scope.setExtras({
					...extractedContext,
					...options.additionalContext,
				});
			}
			if (options.tags) {
				scope.setTags(options.tags);
			}
			const eventId = this.hub.captureException(error);

			if (!silent) {
				// eslint-disable-next-line no-console
				console.info(
					`The following error (${eventId}) has been remotely logged: ${error.message}`
				);
			}
		});

		if (!this.hub && !silent) {
			console.error(error, {
				extractedContext,
				additionalContext: options.additionalContext,
			});
		}
	};

	public addBreadcrumb: AddBreadcrumb = breadcrumb => {
		this.hub?.addBreadcrumb(breadcrumb);
	};

	public setLoggingTags: SetLoggingTags = tags => {
		this.hub?.configureScope(scope => {
			scope.setTags(tags);
		});
	};

	public setUserId = (id: string) => {
		this.hub?.configureScope(scope => {
			scope.setUser({ id });
		});

		const sentry = getSentry();
		if (sentry) {
			sentry.getCurrentHub().configureScope(scope => {
				scope.setUser({ id });
			});
		}
	};
}

function getDiscardedHostUrls() {
	const { isNative } = detectNativePlatform();
	const discardedHostUrls: RegExp[] = [];
	if (!isNative) {
		discardedHostUrls.push(/^file\:\/\//);
	}
	return discardedHostUrls;
}

function processCommonEventTransformations(event: SentryType.Event) {
	event.exception?.values?.forEach(exception => {
		exception.value = exception.value
			?.replace(
				/https?\:\/\/([^\.]*)\.cloud(burrito)?\.com\//gi,
				'https://{customer_subdomain}.cloud$2.com/'
			)
			.replace(/serviceWorker\..{10}\.js/gi, 'serviceWorker.js');
		if (exception.type === 'ChunkLoadError') {
			exception.value = exception.value?.replace(/\..{20}\.js/gi, '.js');
		}
	});
	event.message = event.message || event.exception?.values?.map(v => v.value).join('\n');
	return event;
}

export function createSentryLogger(options: SentryType.BrowserOptions = {}) {
	const logger = new SentryLogger({
		blacklistUrls: getDiscardedHostUrls(),
		...options,
	});

	logger.setLoggingTags({ 'wsui-session-id': WSUI_SESSION_ID });

	logger.configureScope(scope => {
		scope.setExtra('errorgroup', getErrorGroupId());
		scope.addEventProcessor(processCommonEventTransformations);
	});
	return logger;
}
