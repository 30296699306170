import * as React from 'react';
import { t } from '@citrite/translate';
import { notifyError } from '@citrite/web-ui-component';
import { CacheFactory, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { Location } from 'history';
import { useLocation } from 'react-router-dom';
import { logError } from 'remoteLogging';
import { v4 } from 'uuid';
import { _aot, AotStrings } from 'App/AotTrace';
import { getErrorPageUrlForLanguage } from 'App/Common/routing';
import { shouldDetectClientBeforeAuth } from 'App/DetectionFlowUtils';
import {
	LocalAppOnboardingModal,
	synchronizeLocalApps,
	updateLocalAppsWithWhitelistedApps,
} from 'App/LocalSystemApps';
import { localAppToResource } from 'App/LocalSystemApps/localAppToResource';
import { Route, useRouteAvailability } from 'App/Navigation/useRouteAvailability';
import { ConditionalRenderer } from 'Components/ConditionalRenderer';
import { Detection } from 'Components/Detection';
import { EventType, UpdateRecentsEvent } from 'Components/EventBus';
import { Configuration } from 'Configuration/Context';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { environment } from 'Environment';
import { setRefreshResourcesFunction } from 'Environment/callbacks';
import { hasUIProperty, UIProperty } from 'Environment/hasUIProperty';
import { launchResource } from 'Environment/launchResource';
import { postResourcesToChromeApp } from 'Environment/launchResource/chromeApp';
import {
	autoLaunchedDoneKey,
	getLaunchSingleDesktop,
} from 'Environment/launchResource/clientManager';
import { isCitrixChromeApp } from 'Environment/launchResource/device';
import {
	processInstalledApps,
	processSiriConfiguredApps,
} from 'Environment/processInstalledApps';
import {
	isStoreFrontFallbackEnabled,
	StoreFrontFallbackErrorPage,
} from 'ErrorPage/StoreFrontFallback';
import { setInSessionStorage } from 'javascript/sf/Storage';
import { isAdvancedWorkspaceResiliencyEnabled } from 'Workspace/advancedWorkspaceResiliency';
import { clientHasAppProtectionCapability } from 'Workspace/AppContext/appProtectionCapabilityCheck';
import { isCitrixBrowserApp } from 'Workspace/AppContext/citrixBrowserResource';
import { getRecentAppsAndDesktopsForNative } from 'Workspace/AppContext/recentAppsAndDesktopsForNative';
import { BrowserExtensionContext } from 'Workspace/BrowserExtension/Context';
import { useBrowserExtension } from 'Workspace/BrowserExtension/useBrowserExtension';
import { BucketManifest, useCacheContext } from 'Workspace/Cache';
import { ChromeAppContext } from 'Workspace/ChromeApp/ChromeAppContext';
import { useChromeApp } from 'Workspace/ChromeApp/useChromeApp';
import { fetchResources, loginRedirectionErrorName } from 'Workspace/fetchResources';
import {
	ResourceContextProvider,
	useLoadableResourceContext,
} from 'Workspace/ResourceProvider';
import { DevicePosture } from 'Workspace/ResourceProvider/Context';
import {
	isLocalApp,
	ListApiResult,
	Resource,
} from 'Workspace/ResourceProvider/resourceTypes';
import { UserContext, useUserContext } from 'Workspace/UserContext';
import { loadResources } from './loadResources';
type ChildrenFunction = (refreshingResources?: boolean) => JSX.Element;

export interface Props {
	children?: React.ReactNode | ChildrenFunction;
	shouldDetectCWA?: boolean;
	supportsAnonymousRequests?: boolean;
	browserExtensionContext?: BrowserExtensionContext;
	chromeAppContext?: ChromeAppContext;
}

export interface InternalProps extends Props {
	configurationContext: Configuration;
	loadableResourceContext: ResourceContextProvider;
	userContext: UserContext;
	location: Location;
	workspaceConfiguration?: WorkspaceConfiguration;
	cacheFactory: CacheFactory;
	areAppsAvailable: boolean;
	areDesktopsAvailable: boolean;
}

interface State {
	refreshingResources?: boolean;
	shouldShowStorefrontFallback?: boolean;
}

const resourcesCacheKey = 'cvadResourceData';

type ResourcesResult = Omit<ListApiResult, 'resources' | 'isFromCache'> & {
	filteredResources: Resource[];
	unfilteredResources: Resource[];
	filesResource: Resource;
	filesOnly: boolean;
	isFromServerCache: boolean;
	isFromClientCache: boolean;
	preferLeaseLaunchIds: string[];
	isNetworkValidatedEnumeration?: boolean;
	citrixBrowserResourceID?: string;
};

class _AppContext extends React.Component<InternalProps, State> {
	public state: State = {};
	private resourcesCache = this.props.userContext.userDetails?.userId
		? this.props.cacheFactory.createUnencryptedCacheBucket(BucketManifest.resources, {
				perUser: true,
		  })
		: undefined;

	public render() {
		if (this.state.shouldShowStorefrontFallback) {
			return <StoreFrontFallbackErrorPage />;
		}

		const detectClientBeforeAuthentication = shouldDetectClientBeforeAuth(
			this.props.workspaceConfiguration
		);

		return (
			<ConditionalRenderer
				flag={detectClientBeforeAuthentication}
				OnComponent={React.Fragment}
				OffComponent={Detection}
				OffComponentProps={{ shouldDetectCWA: this.props.shouldDetectCWA }}
			>
				{hasUIProperty(this.props.workspaceConfiguration, UIProperty.Apps) &&
					environment.supportsUserManagedLocalApps && <LocalAppOnboardingModal />}
				{typeof this.props.children === 'function'
					? (this.props.children as ChildrenFunction)(this.state.refreshingResources)
					: this.props.children}
			</ConditionalRenderer>
		);
	}

	public componentDidMount() {
		setRefreshResourcesFunction(this.refreshResources);
		this.executeEnvironmentReadyAfterPaint();

		this.initializeResourceContext().catch(error => {
			if (error.name === loginRedirectionErrorName) {
				return;
			}
			logError(error, {
				tags: { feature: 'apps-and-desktops' },
				customMessage: 'Resource Context initialization failed',
			});
			if (isStoreFrontFallbackEnabled()) {
				this.setState({ shouldShowStorefrontFallback: true });
				return;
			}
			if (error && error.status) {
				location.assign(getErrorPageUrlForLanguage('jserror'));
			}
		});
	}

	private async getAllSiriConfiguredApps() {
		environment.getSiriRegisteredApps().then(siriResourceData => {
			this.props.loadableResourceContext.value.updateSession(state => ({
				...processSiriConfiguredApps(state.resources, siriResourceData),
			}));
		});
	}

	private executeEnvironmentReadyAfterPaint = () => {
		setImmediate(environment.ready);
	};

	private async initializeResourceContext() {
		const initialResult = await this.getResources({ allowCachedResults: true });
		this.updateResourceContext(initialResult);

		const hasUserLoggedIn = await this.props.userContext.hasLoggedIn();
		if (hasUserLoggedIn) {
			this.autoLaunchSingleDesktopIfNecessary(initialResult.filteredResources);
		}
		this.props.loadableResourceContext.value.setLoading(false);

		if (initialResult.isFromClientCache || initialResult.isFromServerCache) {
			/**
			 * For load performance improvement changes, the non cached resources fetch from network use to happen in parallel with the login
			 * which with race condition can cause login to be prompted multiple times. To avoid this, we are waiting for the user details.
			 *
			 * Waits for the user to complete the login and it queues the background resources synchronization.
			 * This is to ensure that the resources are fetched from the network after the user has logged in.
			 *
			 * // OnPrem specific changes
			 * Where as in onPrem, the login itself is initiated from the resources fetch, so no need to wait for user details.
			 * This should be the mechanism util onprem storefront has /GetUserDetails API as similar to cloud or a generic way for user login.
			 */
			if (IS_ON_PREM || hasUserLoggedIn) {
				this.queueBackgroundResourcesSync();
			}
		}
	}

	private setCachedResourceData(listApiResult: ListApiResult) {
		if (!this.resourcesCache) {
			return;
		}
		const cacheSafeResources = listApiResult.resources
			.filter(resource => !isLocalApp(resource))
			.map(resource => {
				const { accessssodata, isnewresource, ...rest } = resource;
				return rest;
			});
		return this.resourcesCache.setUnencrypted(resourcesCacheKey, {
			...listApiResult,
			resources: cacheSafeResources,
		});
	}

	private async getResources({
		allowCachedResults,
	}: {
		allowCachedResults: boolean;
	}): Promise<ResourcesResult> {
		const { workspaceConfiguration } = this.props.configurationContext;
		const { supportsAnonymousRequests, location } = this.props;
		const cachedResponse: ListApiResult =
			this.resourcesCache?.getUnencrypted(resourcesCacheKey);

		let preferLeaseLaunchIds: string[] = [];
		let resourceResponse: ListApiResult;
		let isNetworkValidatedEnumeration = false;
		if (allowCachedResults && cachedResponse) {
			resourceResponse = cachedResponse;
		} else {
			_aot.info(AotStrings.enumeration.category, AotStrings.enumeration.fetchResources);
			const networkResponse = await fetchResources({
				listUrl: workspaceConfiguration.storeProxy.resourcesProxy.listURL,
				allowUnauthenticated: supportsAnonymousRequests,
				location,
				acceptsCachedResults: allowCachedResults,
				isAppProtectionEnabled: clientHasAppProtectionCapability(workspaceConfiguration),
				cachedResources: cachedResponse?.resources,
			});

			_aot.info(
				AotStrings.enumeration.category,
				AotStrings.enumeration.resourcesLoadedFromCache,
				cachedResponse?.resources.length
			);
			if (
				networkResponse?.enumerationResult !== 'Failed' &&
				networkResponse?.enumerationResult !== 'Partial' &&
				!networkResponse.isFromCache
			) {
				isNetworkValidatedEnumeration = true;
			}

			const mergeResult = this.mergePartialResourcesWithCache(
				networkResponse,
				cachedResponse
			);

			_aot.info(
				AotStrings.enumeration.category,
				AotStrings.enumeration.resourcesLoadedFromCacheAndNetwork,
				mergeResult.mergedResources.length,
				networkResponse?.enumerationResult
			);
			preferLeaseLaunchIds = mergeResult.preferLeaseLaunchIds;
			networkResponse.resources = mergeResult.mergedResources;
			this.setCachedResourceData(networkResponse);
			resourceResponse = networkResponse;
		}

		const {
			enumerationResult,
			resources: unfilteredResources,
			isFromCache: isFromServerCache,
			...restOfResponse
		} = resourceResponse;
		const filesResource = unfilteredResources.find(resource =>
			resource.keywords?.includes('ShareFileUI')
		);

		let filteredResources = loadResources(workspaceConfiguration, resourceResponse);
		if (environment.supportsLocalApps) {
			const whitelistedLocalAppsFromNative = await environment.getLocalAppWhitelist();
			if (whitelistedLocalAppsFromNative.length > 0) {
				const whitelistedLocalAppsResources = whitelistedLocalAppsFromNative.map(
					localApp => {
						return localAppToResource(localApp, true);
					}
				);
				await updateLocalAppsWithWhitelistedApps(whitelistedLocalAppsResources);
			}

			const localAppsFromNative = await environment.getLocalApps();
			const localAppsSyncFromNative = await synchronizeLocalApps(localAppsFromNative);

			filteredResources = filteredResources.concat(localAppsSyncFromNative);
		}

		const filesOnly = !!filesResource && unfilteredResources.length === 1;
		const { disallowICADownload, devicePosture } = resourceResponse;
		return {
			...restOfResponse,
			unfilteredResources,
			filteredResources,
			filesResource,
			filesOnly,
			isFromServerCache,
			isFromClientCache: resourceResponse === cachedResponse,
			preferLeaseLaunchIds,
			enumerationResult,
			isNetworkValidatedEnumeration,
			disallowICADownload,
			devicePosture: this.getDevicePostureData(devicePosture),
			citrixBrowserResourceID:
				filteredResources && filteredResources.find(isCitrixBrowserApp)?.id,
		};
	}

	private getDevicePostureData(devicePosture: DevicePosture) {
		const { state, scanTransactionId } = devicePosture || {};
		return devicePosture &&
			!devicePosture.errorCode &&
			devicePosture.state &&
			devicePosture.scanTransactionId
			? { state, scanTransactionId }
			: null;
	}

	private updateResourceContext({
		filteredResources,
		unfilteredResources,
		filesOnly,
		isUnauthenticatedStore,
		preferLeaseLaunchIds,
		filesResource,
		isNetworkValidatedEnumeration,
		disallowICADownload,
		citrixBrowserResourceID,
		isSubscriptionEnabled,
		devicePosture,
	}: ResourcesResult) {
		if (isCitrixChromeApp()) {
			postResourcesToChromeApp(unfilteredResources);
		}
		this.props.loadableResourceContext.value.updateSession({
			resources: filteredResources,
			isUnauthenticatedStore,
			preferLeaseLaunchIds,
			filesSSOUrl: filesResource && filesResource.accessssodata,
			isNetworkValidatedEnumeration,
			disallowICADownload,
			devicePosture,
			citrixBrowserResourceID,
			isSubscriptionEnabled,
		});

		this.queueResourcesUpdateWithInstalledApps(filesOnly, filteredResources);
	}

	private queueBackgroundResourcesSync() {
		const {
			startRecordingResourceUpdates,
			stopRecordingResourceUpdates,
			applyRecordedResourceUpdates,
		} = this.props.loadableResourceContext.value;
		startRecordingResourceUpdates();

		// a full resource enumeration can take several seconds and may lock the
		// session in workspace platform, causing other requests that use the same
		// session to be queued behind it. wait a few seconds before triggering
		// enumeration to give the app time to start up
		//
		// OnPrem specific changes
		// As the login itself is initiated from the resources fetch, we no need to add the delay for onprem.
		const aFewSeconds = IS_ON_PREM ? 0 : 3000;
		setTimeout(async () => {
			try {
				const liveResult = await this.getResources({ allowCachedResults: false });
				stopRecordingResourceUpdates();
				this.updateResourceContext({
					...liveResult,
					filteredResources: applyRecordedResourceUpdates(liveResult.filteredResources),
				});
				this.getAllSiriConfiguredApps();
			} catch (error) {
				if (error.name === loginRedirectionErrorName) {
					return;
				}
				logError(error, {
					tags: { feature: 'apps-and-desktops' },
					customMessage: 'Background resource synchronization failed',
				});
			}
		}, aFewSeconds);
	}

	private mergePartialResourcesWithCache(
		networkResponse: ListApiResult,
		cachedResponse: ListApiResult
	) {
		const { workspaceConfiguration } = this.props.configurationContext;
		const isIncompleteEnumeration =
			networkResponse.enumerationResult === 'Partial' ||
			networkResponse.enumerationResult === 'Failed';
		const allowMergingCachedResources =
			isAdvancedWorkspaceResiliencyEnabled(workspaceConfiguration) ||
			this.props.browserExtensionContext?.isSupportedCustomerRuntime ||
			this.props.chromeAppContext?.isSupportedCustomerRuntime;
		if (isIncompleteEnumeration && cachedResponse && allowMergingCachedResources) {
			let resourcesToAddFromCache = cachedResponse.resources.filter(
				cached =>
					cached.clmetadata?.leasesupported &&
					!networkResponse.resources.find(r => r.id === cached.id)
			);

			const shouldFilterBasedOnFeedInfo =
				networkResponse.enumerationResult === 'Partial' &&
				networkResponse.feedInfo?.length > 0;

			if (shouldFilterBasedOnFeedInfo) {
				const failedFeedIds = networkResponse.feedInfo
					.filter(feed => feed.enumerationResult.toLowerCase() !== 'successful')
					.map(feed => feed.id);

				resourcesToAddFromCache = resourcesToAddFromCache.filter(cached =>
					cached.sourceFeedIds?.some(id => failedFeedIds.includes(id))
				);
			}

			return {
				mergedResources: [...networkResponse.resources, ...resourcesToAddFromCache],
				// This is a performance concern, but one with high impact in a failure scenario.
				// If a resource provider is offline because it's timing out, and that's why we're
				// missing resources from it, we don't want to try to launch from it only to have
				// to wait 30 seconds or more for it to fail before we fall back to lease launch.
				preferLeaseLaunchIds: resourcesToAddFromCache.map(r => r.id),
			};
		}

		return {
			mergedResources: networkResponse.resources,
			preferLeaseLaunchIds: [],
		};
	}

	private queueResourcesUpdateWithInstalledApps(
		filesOnly: boolean,
		filteredResources: Resource[]
	) {
		if (!filesOnly) {
			environment.getInstalledResources(filteredResources).then(installedResourceData => {
				this.props.loadableResourceContext.value.updateSession(state => ({
					...processInstalledApps(state.resources, installedResourceData),
				}));
			});
		}
	}

	private autoLaunchSingleDesktopIfNecessary(resources: Resource[]) {
		const desktop = getLaunchSingleDesktop(
			this.props.configurationContext.workspaceConfiguration,
			resources
		);
		if (desktop) {
			setInSessionStorage(autoLaunchedDoneKey, true);
			launchResource({
				resourceContext: this.props.loadableResourceContext.value,
				workspaceConfiguration: this.props.configurationContext.workspaceConfiguration,
				launchOptions: {
					isAutoLaunch: true,
				},
				resource: desktop,
			});
		}
	}

	public componentDidUpdate(prevProps: InternalProps) {
		if (
			this.props.loadableResourceContext.value.resources &&
			this.props.loadableResourceContext.value.resources !==
				prevProps.loadableResourceContext.value.resources
		) {
			this.updateExistingCacheWithChangedResources();
			this.updateAppsAndDesktopsRecentsOnNative();
		}
	}

	private updateAppsAndDesktopsRecentsOnNative() {
		const recentAppsAndDesktops = getRecentAppsAndDesktopsForNative(
			this.props.loadableResourceContext.value.resources
		);
		const event: UpdateRecentsEvent = {
			id: v4(),
			type: EventType.UPDATE_RECENTS,
			payload: {
				recents: recentAppsAndDesktops,
				areAppsAvailable: this.props.areAppsAvailable,
				areDesktopsAvailable: this.props.areDesktopsAvailable,
			},
		};
		environment.sendEventToNative(event);
	}

	private updateExistingCacheWithChangedResources() {
		const existingCache =
			this.resourcesCache &&
			this.resourcesCache.getUnencrypted<ListApiResult>(resourcesCacheKey);
		if (!existingCache) {
			return;
		}

		this.setCachedResourceData({
			...existingCache,
			resources: this.props.loadableResourceContext.value.resources,
		});
	}

	private refreshResources = async (automaticRefresh: boolean) => {
		if (this.state.refreshingResources) {
			return;
		}

		if (!automaticRefresh) {
			this.setState({ refreshingResources: true });
		}

		environment.noteRefreshStart();

		try {
			await this.props.configurationContext.refreshWorkspaceConfiguration();
			const liveResult = await this.getResources({ allowCachedResults: false });
			this.updateResourceContext(liveResult);
			environment.refreshComplete(true);
		} catch (e) {
			environment.refreshComplete(false).then(() => {
				if (!automaticRefresh) {
					notifyError(t('Workspace:refresh_failed'));
				}
			});
		} finally {
			this.setState({ refreshingResources: false });
		}

		this.getAllSiriConfiguredApps();
	};

	public componentWillUnmount() {
		setRefreshResourcesFunction(() => {});
	}
}

export function AppContext(props: Props) {
	const configurationContext = useConfigurationContext();
	const loadableResourceContext = useLoadableResourceContext();
	const userContext = useUserContext();
	const location = useLocation();
	const browserExtensionContext = useBrowserExtension();
	const chromeAppContext = useChromeApp();
	const workspaceConfiguration = configurationContext.workspaceConfiguration;
	const { cacheFactory } = useCacheContext();
	const areAppsAvailable = useRouteAvailability(Route.App);
	const areDesktopsAvailable = useRouteAvailability(Route.Desktop);
	return (
		<_AppContext
			{...props}
			configurationContext={configurationContext}
			loadableResourceContext={loadableResourceContext}
			userContext={userContext}
			location={location}
			browserExtensionContext={browserExtensionContext}
			chromeAppContext={chromeAppContext}
			workspaceConfiguration={workspaceConfiguration}
			cacheFactory={cacheFactory}
			areAppsAvailable={areAppsAvailable}
			areDesktopsAvailable={areDesktopsAvailable}
		/>
	);
}
