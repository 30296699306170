import React from 'react';
import { t } from '@citrite/translate';
import {
	layout,
	ShowModal,
	typography,
	WithModalsProps,
} from '@citrite/web-ui-component';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { ContextMenuItem } from '@citrite/workspace-ui-platform-react';
import styled from '@emotion/styled';
import { trackAnalyticsEvent } from 'analytics';
import { launchGenericResource } from 'App/Tile/utils';
import { restartDesktop } from 'Components/ResourceTile/restartDesktop';
import { configureSiriForResource } from 'Components/ResourceTile/Siri/configureSiri';
import { ViewAppDetails, ViewDesktopDetails } from 'Components/ResourceTile/ViewDetails';
import { LaunchParams } from 'Environment/launchResource/launchResource';
import { shouldEnableSiri } from 'Environment/utils';
import { ResourceContext } from 'Workspace/ResourceProvider';
import { Resource, subscriptionStatus } from 'Workspace/ResourceProvider/resourceTypes';
import { AppsUserEventPayload } from 'Workspace/TelemetryEvents/appsEvent/AppsUserEventPayload';
import { DesktopsUserEventPayload } from 'Workspace/TelemetryEvents/desktopsEvent/DesktopsUserEventPayload';
import { EventSite } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const StyledLabel = styled.label`
	font-size: ${layout.mediumSpace};
	font-weight: ${typography.weights.medium};
`;

type BuilderProps = {
	resource: Resource;
	resourceContext: ResourceContext;
	workspaceConfiguration: WorkspaceConfiguration;
	hasSubscriptionsEnabled: boolean;
	favoriteRef: React.RefObject<HTMLInputElement>;
	showModal: WithModalsProps['showModal'] | null;
};

const resourceLaunchOption = ({
	resource,
	resourceContext,
	workspaceConfiguration,
}: LaunchParams) => ({
	onClick: () =>
		launchGenericResource({ resource, resourceContext, workspaceConfiguration }),
	children: (
		<StyledLabel>
			{resource.isdesktop ? t('Workspace:open_desktop') : t('Workspace:open_app')}
		</StyledLabel>
	),
});

const restartDesktopOptions = (
	resource: Resource,
	resourceContext: ResourceContext,
	workspaceConfiguration: WorkspaceConfiguration
) => {
	const reStarting = resourceContext.desktopRestartInProgressIds.includes(resource.id);
	return {
		onClick: () => {
			if (!reStarting) {
				restartDesktop(resource, { resourceContext }, workspaceConfiguration);
			}
		},
		children: (
			<StyledLabel>
				{reStarting
					? t('Workspace:restart_in_progress')
					: t('Workspace:restart_desktop_modal.restart')}
			</StyledLabel>
		),
	};
};

/**
 * Should extend this method to include more options of desktop resource
 * like logoff, last login, settings etc.
 */
const getMenuItemsForDesktop = (
	resource: Resource,
	resourceContext: ResourceContext,
	workspaceConfiguration: WorkspaceConfiguration
): ContextMenuItem[] => {
	const menuItem = [];
	if (resource.poweroffurl) {
		menuItem.push(
			restartDesktopOptions(resource, resourceContext, workspaceConfiguration)
		);
	}
	return menuItem;
};

const getSubscriptionMenuItem = (
	favoriteRef: React.RefObject<HTMLInputElement>,
	resource: Resource
) => {
	if (!resource.disabled && !!resource.subscriptionurl) {
		return {
			onClick: () => {
				favoriteRef?.current?.click();
			},
			children: (
				<StyledLabel>
					{resource.subscriptionstatus === subscriptionStatus.subscribed
						? t('Workspace:remove_favorite')
						: t('Workspace:add_favorite')}
				</StyledLabel>
			),
		};
	} else {
		return false;
	}
};

const getSiriHandlerForIOS = (resource: Resource, resourceContext: ResourceContext) => ({
	onClick: () => {
		configureSiriForResource(resource, resourceContext);
	},
	children: (
		<StyledLabel>
			{resource.isResourceRegisteredToSiri
				? t('Workspace:registered_to_siri')
				: t('Workspace:not_registered_to_siri')}
		</StyledLabel>
	),
});

const getViewDetailsOptions = (resource: Resource, showModal: ShowModal) => ({
	children: <StyledLabel>{t('Workspace:view_details')}</StyledLabel>,
	onClick: () => {
		if (resource.isdesktop) {
			trackAnalyticsEvent(
				DesktopsUserEventPayload.moreInfo(EventSite.Tile, resource.type)
			);
			showModal(<ViewDesktopDetails resource={resource} />);
		} else {
			trackAnalyticsEvent(AppsUserEventPayload.moreInfo(EventSite.Tile, resource.type));
			showModal(<ViewAppDetails resource={resource} />);
		}
	},
});

export const moreOptionsBuilder = ({
	resource,
	resourceContext,
	workspaceConfiguration,
	hasSubscriptionsEnabled,
	favoriteRef,
	showModal,
}: BuilderProps): ContextMenuItem[] => {
	const contextMenu: ContextMenuItem[] = [
		resourceLaunchOption({ resource, resourceContext, workspaceConfiguration }),
	];

	if (resource.isdesktop) {
		contextMenu.push(
			...getMenuItemsForDesktop(resource, resourceContext, workspaceConfiguration)
		);
	}
	if (!resource.mandatory && hasSubscriptionsEnabled) {
		const subscriptionMenuItem = getSubscriptionMenuItem(favoriteRef, resource);
		if (subscriptionMenuItem) {
			contextMenu.push(subscriptionMenuItem);
		}
	}

	if (shouldEnableSiri()) {
		contextMenu.push(getSiriHandlerForIOS(resource, resourceContext));
	}

	showModal && contextMenu.push(getViewDetailsOptions(resource, showModal));
	return contextMenu;
};
